import React, { useContext, useState, useEffect } from "react";
import { AlertContext } from "controller/context/alertContext";
import { useNavigate } from "react-router-dom";
import envConfig from "../../../env/env.json";
import * as Yup from "yup";
import {
  useRestaurantValues,
  useOneRestaurant,
  useCuisinesList,
  useCategoriesList,
} from "model/queryCalls/queryCalls";
import {
  AddRestaurantValues,
  deleteRestaurant,
  updateRestaurant,
  UpdateOneRestaurantStatus,
} from "controller/services/restaurantServices";
import { FormValues } from "./restaurant.interface";
import { fetchedOptions } from "./dummyData";
import { UserContext } from "controller/context/userContext";
import { useImageUpload } from "components/ ImageUploadUtility";

export const useRestaurant = (handleNext: any) => {
  const { setAlert } = useContext(AlertContext);
  const { userType } = useContext(UserContext);
  const [restaurantId, setRestaurantId] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );

  const [address, setAddress] = useState<string | null>(null);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [branchId, setBranchId] = useState<string | null>(null);
  const [, setIsSuccessful] = useState<boolean>(false);
  const [location, setLocation] = useState<{ lat: number; lng: number } | null>(
    null
  );

  const {
    handleFileUpload: handleLogoUpload,
    isUploading: isLogoUploading,
    imageInputRef: logoInputRef,
    onSuccess: onLogoSuccess,
    onError: onLogoError,
    onUploadProgress: onLogoUploadProgress,
    handleDeleteImage: handleDeleteLogoFile,
    genericImage: brandImage,
    setGenericImage: setBrandImage,
  } = useImageUpload({
    uploadPath: "restaurants/logos",
  });

  const {
    handleFileUpload: handleBannerUpload,
    isUploading: isBannerUploading,
    imageInputRef: bannerInputRef,
    onSuccess: onBannerSuccess,
    onError: onBannerError,
    onUploadProgress: onBannerUploadProgress,
    handleDeleteImage: handleDeleteBannerFile,
    genericImage: bannerImage,
    setGenericImage: setBannerImage,
  } = useImageUpload({
    uploadPath: "restaurants/banners",
  });

  const navigate = useNavigate();

  const [pinCode, setPinCode] = useState<string>("");
  const [mapCenterLocation, setMapCenterLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [isMapVisible, setIsMapVisible] = useState<boolean>(false);

  const { data: categoriesData } = useCategoriesList({});
  const { data: cuisinesData } = useCuisinesList({});

  const categoryOptions = categoriesData?.data?.map((category: any) => ({
    value: category._id?.toString() || "undefined-id",
    label: category.name || "Unnamed Category",
  }));

  const cuisineOptions = cuisinesData?.data?.map((cuisine: any) => ({
    value: cuisine._id.toString(),
    label: cuisine.name,
  }));

  const { data: oneRestaurantData, refetch: restaurantRefetch } =
    useOneRestaurant({ id: restaurantId });

  useEffect(() => {
    if (restaurantId) {
      restaurantRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  const [initialValues, setInitialValues] = useState<FormValues>({
    name: "",
    description: "",
    type: "",
    isActive: true,
    location: "",
    contactDetails: "",
    username: "",
    password: "",
    paymentStatus: "",
    category: [],
    cuisine: [],
    orderType: [],
    restaurantIcon: null,
    restaurantBanner: null,
    workingHours: {
      sunday: { startTime: "", endTime: "" },
      monday: { startTime: "", endTime: "" },
      tuesday: { startTime: "", endTime: "" },
      wednesday: { startTime: "", endTime: "" },
      thursday: { startTime: "", endTime: "" },
      friday: { startTime: "", endTime: "" },
      saturday: { startTime: "", endTime: "" },
    },
  });

  const getCoordinatesFromPinCode = async (pinCode: string) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${pinCode}&key=${envConfig.MapsAPIKey}`
      );
      const data = await response.json();

      if (data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setMapCenterLocation({ lat: location.lat, lng: location.lng });
        setLocation({ lat: location.lat, lng: location.lng });
        getAddress(location.lat, location.lng);
        setIsMapVisible(true);
      } else {
        setIsMapVisible(true);
        setAlert({
          active: true,
          type: "error",
          message: "Invalid pin code. Please enter a valid one.",
        });
      }
    } catch (error) {
      console.error("Error fetching coordinates from pin code:", error);
      setIsMapVisible(false);
      setAlert({
        active: true,
        type: "error",
        message: "Error fetching location. Please try again later.",
      });
    }
  };

  const getAddress = async (lat: number, lng: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${envConfig.MapsAPIKey}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
      } else {
        setAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address from coordinates:", error);
    }
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat() || 0;
    const lng = event.latLng?.lng() || 0;
    setLocation({ lat, lng });
    getAddress(lat, lng);
  };

  const handlePinCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPinCode(e.target.value);
  };

  const handlePinCodeBlur = () => {
    if (pinCode) {
      getCoordinatesFromPinCode(pinCode);
    }
  };

  const handlePinCodeKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      getCoordinatesFromPinCode(pinCode);
    }
  };
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const paymentStatusOptions = [
    { value: "Paid", label: "Paid" },
    { value: "Unpaid", label: "Unpaid" },
  ];
  const mapIdsToOptions = (
    ids: string[],
    options: { value: string; label: string }[]
  ) => {
    return ids
      .filter((id) => id !== null && id !== undefined)
      .map((id) => options?.find((option) => option.value === id.toString()))
      .filter(Boolean);
  };

  // Helper to extract working hours
  const extractWorkingHours = (workingHoursArray: any[]) => {
    const defaultWorkingHours = {
      startTime: "",
      endTime: "",
    };

    const days = {
      sunday:
        workingHoursArray.find((day) => day.name === "Sunday") ||
        defaultWorkingHours,
      monday:
        workingHoursArray.find((day) => day.name === "Monday") ||
        defaultWorkingHours,
      tuesday:
        workingHoursArray.find((day) => day.name === "Tuesday") ||
        defaultWorkingHours,
      wednesday:
        workingHoursArray.find((day) => day.name === "Wednesday") ||
        defaultWorkingHours,
      thursday:
        workingHoursArray.find((day) => day.name === "Thursday") ||
        defaultWorkingHours,
      friday:
        workingHoursArray.find((day) => day.name === "Friday") ||
        defaultWorkingHours,
      saturday:
        workingHoursArray.find((day) => day.name === "Saturday") ||
        defaultWorkingHours,
    };

    return days;
  };

  useEffect(() => {
    if (
      restaurantId &&
      oneRestaurantData &&
      categoryOptions &&
      cuisineOptions
    ) {
      setBranchId(oneRestaurantData?.branchDetails[0]?._id);
      setInitialValues((prevValues) => {
        if (prevValues.name !== oneRestaurantData?.name?.en) {
          const mappedCategories = mapIdsToOptions(
            oneRestaurantData.branchDetails[0]?.category,
            categoryOptions
          );
          const mappedCuisines = mapIdsToOptions(
            oneRestaurantData.branchDetails[0]?.cuisine,
            cuisineOptions
          );

          const workingHours = extractWorkingHours(
            oneRestaurantData?.branchDetails[0]?.workingHours || []
          );

          return {
            name: oneRestaurantData?.name?.en || "",
            description: oneRestaurantData?.description || "",
            type: oneRestaurantData?.branchDetails[0]?.type || "",
            isActive: oneRestaurantData?.isActive || true,
            location: oneRestaurantData?.location?.coordinates || {
              lat: 0,
              lng: 0,
            },
            contactDetails:
              oneRestaurantData?.branchDetails[0]?.contactDetails || "",
            username: oneRestaurantData?.branchDetails[0]?.username || "",
            password: oneRestaurantData?.branchDetails[0]?.password || "",
            paymentStatus:
              oneRestaurantData?.branchDetails[0]?.paymentStatus || "",

            cuisine: mappedCuisines.map((cuisId) => cuisId.value),
            category: mappedCategories.map((catId) => catId.value),
            orderType: oneRestaurantData?.branchDetails[0]?.orderType || [],
            restaurantIcon: oneRestaurantData?.branchDetails[0]?.logo || null,
            restaurantBanner:
              oneRestaurantData?.branchDetails[0]?.banner || null,
            workingHours,
          };
        }
        return prevValues;
      });

      if (oneRestaurantData?.branchDetails[0]?.logo) {
        setBrandImage(
          (prevImage) => prevImage || oneRestaurantData?.branchDetails[0]?.logo
        );
      }

      if (oneRestaurantData?.branchDetails[0]?.banner) {
        setBannerImage(
          (prevBanner) =>
            prevBanner || oneRestaurantData?.branchDetails[0]?.banner
        );
      }

      setLocation((prevLocation) => {
        return prevLocation?.lat !==
          oneRestaurantData?.address?.location?.coordinates?.lat
          ? oneRestaurantData?.address?.location?.coordinates
          : prevLocation;
      });

      setAddress((prevAddress) => {
        return prevAddress !==
          oneRestaurantData?.branchDetails[0]?.address?.address
          ? oneRestaurantData?.branchDetails[0]?.address?.address
          : prevAddress;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId, oneRestaurantData, categoryOptions, cuisineOptions]);

  useEffect(() => {
    const fetchOptions = () => {
      setOptions(fetchedOptions);
    };
    fetchOptions();
  }, []);

  const {
    data: restaurantData,
    status,
    refetch,
  } = useRestaurantValues({
    page,
    rowsPerPage,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setRowsPerPage(+event.target.value);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your restaurant name"),
    description: Yup.string().required("Please enter a description"),
    type: Yup.string().required("Please select your restaurant type"),
    contactDetails: Yup.string().required("Please enter your contact details"),
    username: Yup.string()
      .email("Please enter a valid email address")
      .required("Please enter a valid email"),
    password: Yup.string().required("Please enter a password"),
    paymentStatus: Yup.string().required("Please select a payment status"),
    cuisine: Yup.array().of(Yup.string()).required("Please select a cuisine"),
    category: Yup.array().of(Yup.string()).required("Please select a category"),
    orderType: Yup.array()
      .of(Yup.string())
      .required("Please select at least one order type"),
  });

  const transformWorkingHours = (workingHours: any) => {
    const days = [
      { name: "Sunday", key: "sunday" },
      { name: "Monday", key: "monday" },
      { name: "Tuesday", key: "tuesday" },
      { name: "Wednesday", key: "wednesday" },
      { name: "Thursday", key: "thursday" },
      { name: "Friday", key: "friday" },
      { name: "Saturday", key: "saturday" },
    ];

    return days.map((day) => ({
      name: day.name,
      status:
        workingHours[day.key]?.startTime && workingHours[day.key]?.endTime
          ? true
          : false,
      startTime: workingHours[day.key]?.startTime || "",
      endTime: workingHours[day.key]?.endTime || "",
    }));
  };

  const onSubmit = async (values: FormValues) => {
    const transformedWorkingHours = transformWorkingHours(values.workingHours);
    setLoading(true);

    try {
      const restaurantData = {
        ...values,
        workingHours: transformedWorkingHours,
        logo: brandImage,
        banner: bannerImage,
      };

      if (restaurantId) {
        const updateRes = await updateRestaurant({
          restaurantId,
          data: restaurantData,
          location: location,
          address: address,
          userType: userType,
        });
        if (updateRes) {
          setBranchId(updateRes.branchId);
          setAlert({
            active: true,
            type: "success",
            message: "Restaurant updated successfully",
          });
          setIsSuccessful(true);
          handleNext();
        }
      } else {
        const result = await AddRestaurantValues({
          data: restaurantData,
          location: location,
          address: address,
          userType: userType,
        });
        if (result) {
          setBranchId(result.insertedId);
          setAlert({
            active: true,
            type: "success",
            message: "Restaurant created successfully",
          });
          setIsSuccessful(true);
          handleNext();
        }
      }
    } catch (error: any) {
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Failed to submit. Please try again.",
      });
      setIsSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (restaurantId: string) => {
    try {
      const result = await deleteRestaurant(restaurantId, userType);
      if (result?.restaurantDeleteResult?.deletedCount > 0) {
        setAlert({
          active: true,
          type: "success",
          message: "Restaurant deleted successfully",
        });
        refetch();
      } else {
        throw new Error("Failed to delete the restaurant");
      }
    } catch (error) {
      console.error("Delete restaurant error:", error);
      setAlert({
        active: true,
        type: "error",
        message: "Failed to delete restaurant. Please try again.",
      });
    }
  };

  const onToggleActive = async (restaurantId: string, newIsActive: boolean) => {
    try {
      await UpdateOneRestaurantStatus({
        id: restaurantId,
        isActive: !newIsActive,
        userType: userType,
      });
      refetch();

      setAlert({
        active: true,
        type: "success",
        message: "Restaurant status updated successfully",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Failed to update restaurant status",
      });
    }
  };

  return {
    page,
    rowsPerPage,
    navigate,
    userType,
    handleChangePage,
    handleChangeRowsPerPage,
    onDelete,
    loading,
    setLoading,
    initialValues,
    validationSchema,
    onSubmit,
    options,
    isActive,
    setIsActive,
    refetch,
    location,
    setLocation,
    branchId,
    restaurantData,
    status,
    setAddress,
    address,
    restaurantId,
    setRestaurantId,
    oneRestaurantData,
    onToggleActive,
    categoryOptions,
    cuisineOptions,
    mapIdsToOptions,
    mapCenterLocation,
    isMapVisible,
    handleMapClick,
    handlePinCodeChange,
    handlePinCodeBlur,
    handlePinCodeKeyDown,
    daysOfWeek,
    pinCode,
    paymentStatusOptions,

    handleBannerUpload,
    isBannerUploading,
    bannerInputRef,
    onBannerSuccess,
    onBannerError,
    onBannerUploadProgress,
    handleDeleteBannerFile,
    bannerImage,

    handleLogoUpload,
    isLogoUploading,
    logoInputRef,
    onLogoSuccess,
    onLogoError,
    onLogoUploadProgress,
    handleDeleteLogoFile,
    brandImage,
  };
};
